<projects class="row">
  <div
    is="cell"
    title="Vanessa Barone"
    image="/assets/images/barone_2.jpg"
    sub-title="assistant professor of biology"
    mail="vbarone@stanford.edu"
    twitter="https://twitter.com/vane_barone"
    cv="/assets/pdfs/barone_cv.pdf"
  >
    <div class="container container-md-fluid">
      <h3 class="d-none d-md-block">Vanessa Barone</h3>

      <p>
        Vanessa studied molecular biology at Universita` delle Scienze di Torino, Italy and then pursued her PhD in the Carl-Philipp Heisenberg group at the Institute of Science and Technology Austria in Vienna, Austria. There she investigated how cell-cell contacts regulate cell differentiation of mesendodermal progenitors during zebrafish gastrulation, finding a positive feedback loop between cell-cell contacts and Nodal signalling that determines cell fate decisions. 
      </p>   
  
      <p>
        This opened an evo-devo question: may regulatory interactions between cell adhesion and cell signalling underlie evolutionary changes in tissue patterning? To address this question, Vanessa moved to echinoderm embryos and joined Deirdre Lyons group at the University of California, San Diego where she established the sea urchin and sea star embryos as comparative model systems to investigate the role of cell-cell contacts in the evolution of mesendodermal tissue patterning.
      </p> 

      <p>
        As incoming Faculty at the Department of Biology at Stanford University, Vanessa will continue to explore how variation in the cell behaviours that determine the shape and physical properties of embryos influence the evolution of development.  
      </p> 

      <div class="text-center mt-5 d-md-none">
        <a onclick={event => close(event)}>
          <svg is="icon" name="x" />
          close
        </a>
      </div>
    </div>
  </div>

  <div
    is="cell"
    title="Jana Sipkova"
    sub-title="postdoc"
    image="/assets/images/Sipkova1.jpeg"
    mail="js2335@cam.ac.uk"
    twitter="https://twitter.com/sipkova_j"
    cv="/assets/pdfs/sipkova_cv.pdf"
  >
    <div class="container container-md-fluid">
      <h3 class="d-none d-md-block">Jana Sipkova</h3>

      <p>
       Jana studied Biology at Imperial College London, UK, during which she spent a year in the lab of Tony Hyman at the Max Planck Institute of Molecular Cell Biology and Genetics in Dresden, Germany. It was there that she imaged a developing embryo for the first time and fell in love with developmental biology! She undertook her PhD in Kristian Franze’s group as a part of the Wellcome Trust Developmental Mechanisms program at the University of Cambridge, UK, looking at the interplay between mechanical and chemical signals in brain development. She found that tissue stiffness regulates Eph/ephrin signaling to guide growing neuronal axons to the right place at the right time in the developing frog <br /> <em>Xenopus laevis</em> brain.
      </p>   

      <p>
       As a part of her PhD, Jana also took part in the MBL Embryology Course at Woods Hole, MA. It was there that she got interested in marine invertebrates and exploiting the natural variation between species to address key developmental questions. In the Barone lab, Jana is excited to use her mechanobiology background to investigate the effect of environmental factors on early embryogenesis in sea stars and sea urchins.
      </p>  

      <p>
       When not in the lab, Jana enjoys doing anything outdoors, and can’t wait to explore the beautiful area around Monterey!
      </p>  

      <div class="text-center mt-5 d-md-none">
        <a onclick={event => close(event)}>
          <svg is="icon" name="x" />
          close
        </a>
      </div>
    </div>
  </div>

 <div
    is="cell"
    title="Sareen Fiaz"
    sub-title="technician"
    image="/assets/images/sareen.jpg"
    mail="sareenfiaz@gmail.com"
    twitter="https://linkedin.com/in/sareen-fiaz"
    cv="/assets/pdfs/fiaz_cv.pdf"
  >
    <div class="container container-md-fluid">
      <h3 class="d-none d-md-block">Sareen Fiaz</h3>

      <p>
       Sareen studied Biology at the University of California, Santa Barbara. Following graduation, she accepted a full-time Lab Manager/Research Assistant position in the lab of Dr. Meghan Morrissey at UC Santa Barbara, where she investigated the biophysical properties by which cancer cells may become more vulnerable to macrophage attack in a solid tumor. It was here she developed a passion for cell biology and microscopy. 
      </p>   

      <p>
       In the Barone lab, Sareen is excited to combine her love for marine organisms and microscopy, with her fascination for marine invertebrate development. She is also thrilled to work closely with one of her favorite marine animals, the bat star.
      </p>  

      <p>
       Outside of the lab, Sareen enjoys traveling, tidepooling, snorkeling, camping, and reading.
      </p>   

      <div class="text-center mt-5 d-md-none">
        <a onclick={event => close(event)}>
          <svg is="icon" name="x" />
          close
        </a>
      </div>
    </div>
  </div>

  <div
    is="cell"
    title="This could be you!"
    sub-title="graduate student"
    image="/assets/images/scientist_silhouette_2.jpg"
  >
    <div class="container container-md-fluid">
      <h3 class="d-none d-md-block">This could be you!</h3>

      <p>
        Graduate students interested in joining our brand new lab will be admitted through the Biology <a href= "https://biology.stanford.edu/academics/phd-program">Ph.D Program</a> and are encouraged to contact <a href= "mailto: vbarone@stanford.edu">Vanessa</a>. Things you should love and have some experience in include cell biology, developmental biology, confocal imaging, biophysics, computational modelling of living systems and evo-devo.
      </p>   

      <div class="text-center mt-5 d-md-none">
        <a onclick={event => close(event)}>
          <svg is="icon" name="x" />
          close
        </a>
      </div>
    </div>
  </div>   


  <!-- <hr class="d-md-none w-50 mx-auto my-5" /> -->

  <script>
    export default class {
      close(event) {
        event.preventDefault()

        this.bus.emit('deactivate-projects')
      }
    }
  </script>
</projects>
