<cell class="col-12 col-md-6 col-lg-4 mb-4 mb-md-5 {classes()}">
  <div class="front elevate" onclick={event => toggle(event)}>
    <div class="image d-flex justify-content-center">
      <img
        src="{props.image}"
        class="elevate"
      />
    </div>

    <div class="fs-4 text-center pt-4 sans-serif">
      <div class="title">{props.title}</div>
      <div class="sub-title">{props.subTitle}</div>
    </div>

    <div if={props.mail || props.twitter || props.cv} class="buttons pt-4 pb-2">
      <a if={props.mail} href="mailto:{props.mail}">
        <svg is="icon" name="mail" />
      </a>

      <a if={props.twitter} href="{props.twitter}">
        <svg is="icon" name="twitter" />
      </a>

      <a if={props.cv} href="{props.cv}">
        <svg is="icon" name="file-text" />
      </a>
    </div>

    <div class="read-more d-flex justify-content-center mt-4 pb-5">
      <svg is="icon" name="arrow-right" class="me-2" />
      read more
    </div>

    <div if={!props.mail && !props.twitter && !props.cv} class="pt-3 pb-4">

    </div>
  </div>

  <div class="description collapse">
    <slot class="d-block my-4" />
  </div>

  <div
    class="detail back {classes()}"
    ontransitionend={afterTransition}
  >
    <div class="w-md-50 p-md-5">
      <div class="d-none d-md-block">
        <div class="name">{props.name}</div>
        <div class="role mb-3">{props.role}</div>
      </div>

      <div class="description my-4">
        <slot class="d-block my-4" />
      </div>
    </div>
  </div>

  <script>
    import {Collapse} from 'bootstrap'
    import {marked} from 'marked'

    import {riot, bus} from '../lib/riot'

    export default class {
      constructor() {
        this.toggle = this.toggle.bind(this)
        this.activate = this.activate.bind(this)
        this.deactivate = this.deactivate.bind(this)
        this.afterTransition = this.afterTransition.bind(this)
      }

      onBeforeMount() {
        let backdrop = document.querySelector('body > .backdrop')
        if (!backdrop) {
          const body = document.documentElement.querySelector('body')
          backdrop = document.createElement('div')
          backdrop.classList.add('backdrop', 'transition-toggle-height')
          body.append(backdrop)
        }

        backdrop.addEventListener('click', this.deactivate)

        this.state = {
          backdrop
        }

        window.addEventListener('resize', event => this.update())
        window.addEventListener('resize', this.deactivate)
        this.on('deactivate-projects', this.deactivate)
      }

      classes() {
        let result = []

        if (this.state.active) result.push('active')

        const rect = this.root.getBoundingClientRect()
        const right = rect.left >= window.innerWidth / 2
        if (right) result.push('right')

        return result.join(' ')
      }

      onMounted() {
        // this.updateSide()
        this.mountComponents()
      }

      mountComponents() {
        for (const raw of this.root.querySelectorAll('raw')) {
          for (const e of raw.querySelectorAll('[is]')) {
            const r = riot.mount(e)
          }
        }
      }

      // updateSide() {
      //   const rect = this.root.getBoundingClientRect()
      //   const right = rect.left > window.innerWidth / 2
      //   this.update({right})
      // }

      onUnmounted() {
        this.state.backdrop.removeEventListener('click', this.deactivate)
      }

      toggle() {
        if (this.state.active) {
          this.deactivate()
        } else {
          this.activate()
        }
      }

      activate() {
        this.state.backdrop.classList.add('active')
        // const selector = this.props.elevate
        // const elevate = this.root.querySelector(selector)
        // elevate.classList.add('elevate')

        const detail = this.root.querySelector('.detail')
        detail.classList.add('active')

        const ce = this.root.querySelector('.collapse')
        const collapsible = Collapse.getOrCreateInstance(ce)
        collapsible.show()

        this.update({active: true})
      }

      deactivate() {
        this.state.backdrop.classList.remove('active')

        const detail = this.root.querySelector('.detail')
        detail.classList.remove('active')

        const ce = this.root.querySelector('.collapse')
        const collapsible = Collapse.getOrCreateInstance(ce)
        collapsible.hide()

        this.update({active: false})
      }

      afterTransition() {
        // const detail = this.root.querySelector('.detail')
        // if (detail.classList.contains('active')) return;

        // const selector = this.props.elevate
        // const elevate = this.root.querySelector(selector)
        // elevate.classList.remove('elevate')
      }

      description() {
        const md = this.props.description || ''
        const html = marked(md, {headerIds: false, mangle: false})

        return html
      }
    }
  </script>
</cell>
