<raw>
  <script>
    export default class {
      setInnerHTML() {
        this.root.innerHTML = this.props.html
      }

      onMounted() {
        this.setInnerHTML()
      }
      
      onUpdated() {
        this.setInnerHTML()
      }
    }
  </script>
</raw>
