<projects class="row">
  <div
    is="cell"
    title="Shape and Fate"
    image="/assets/images/project_1_thumb.png"
    ratio={true}
  >
    <div class="container container-md-fluid">

      <div class="row">
        <div class="col-12 col-md-6">
          <h3 class="d-none d-md-block mt-0">Shape and Fate</h3>

          <p>
            Sea star embryos are less compact than sea urchin embryos, so that cell-cell contacts are in average smaller between sea star cells than they are in sea urchin.
          </p>   
      
          <p>
            We found that these differences may alter the dynamics of Wnt/β-catenin signalling, which determines mesendoderm cell fates in echinoderm embryos.
          </p> 
      
          <p>
            May variation in mesendoderm patterning between these species be due not only to changes in the gene regulatory networks of cell differentiation, but also to changes in embryonic shapes?
          </p> 
        </div>

        <div class="col-12 col-md-6 mt-3 mt-md-0">
          <img src="/assets/images/project_snippet_1.png" class="w-100" />
        </div>
      </div>

      <div class="text-center mt-5 d-md-none">
        <a onclick={event => close(event)}>
          <svg is="icon" name="x" />
          close
        </a>
      </div>
    </div>
  </div>

  <!-- <hr class="d-md-none w-50 mx-auto my-5" /> -->

  <div
    is="cell"
    title="Changing embryo shape"
    image="/assets/images/project_2_thumb.png"
    ratio={true}
  >
    <div class="container container-md-fluid">
      <div class="row">
        <div class="col-12 col-md-6">
          <h3 class="d-none d-md-block mt-0">Changing embryo shape</h3>

          <p>
            Sea urchin embryos are more compact than sea stars': why? Are sea urchin cells more adhesive? Less stiff? Or ... are sea urchin cells just the same as sea star, and embryo shape depends on something else like ... extra-embryonic tissues?
          </p>   
        </div>

        <div class="col-12 col-md-6 mt-3 mt-md-0">
          <img src="/assets/images/project_snippet_2.png" class="w-100" />
        </div>

      </div>

      <div class="text-center mt-5 d-md-none">
        <a onclick={event => close(event)}>
          <svg is="icon" name="x" />
          close
        </a>
      </div>
    </div>
  </div>

  <script>
    export default class {
      close(event) {
        event.preventDefault()

        this.bus.emit('deactivate-projects')
      }
    }
  </script>
</projects>
